import { RouteRecordRaw } from "vue-router";
import HomeView from "./HomeView.vue";
import AboutUsView from "./AboutUsView.vue";

export const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/services",
    name: "services",
    component: HomeView,
    props: {
      section: "services"
    }
  },
  {
    path: "/features",
    name: "features",
    component: HomeView,
    props: {
      section: "features"
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: HomeView,
    props: {
      section: "contact"
    }
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUsView,
  },
  /*{
    path: "/how-it-works",
    name: "how-it-works",
    component: HowItWorksView,
  }*/
];
