import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import QuotationView from "../features/quotation/QuotationView.vue";
import QuotationPdfView from "../features/quotation/QuotationPdfView.vue";
//import { templateRoutes } from "../_Templates/template.route";
import { staticRoutes } from "../features/static/static.route";
import { planRoutes } from "../features/pricing/pricing.route";
//import InvoicePdfView from "../features/invoice/InvoicePdfView.vue";

const routes: Array<RouteRecordRaw> = [
  ...staticRoutes,
  ...planRoutes,
  //...templateRoutes,
  {
    path: "/quotation/:id",
    name: "quotation",
    component: QuotationView,
    props: true
  },
  {
    path: "/quotation/pdf/:id",
    name: "quotation-pdf",
    component: QuotationPdfView,
    props: true
  },
  /*{
    path: "/invoice/pdf/:id",
    name: "invoice-pdf",
    component: InvoicePdfView,
    props: true
  }*/
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
